import { useEffect, useState } from "react";
import "./Test.css";

const App = () => {
  const [animatedText, setAnimatedText] = useState("\u00A0"); // Non-breaking space initially
  const [isH2Visible, setIsH2Visible] = useState(false); // Control visibility of h2

  // Text animation logic
  useEffect(() => {
    const text = "Jifris.com";
    let index = 0;

    const interval = setInterval(() => {
      if (index < text.length) {
        setAnimatedText((prev) => prev + text[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 200); // Adjust speed of text animation if needed

    // Make h2 visible when animation starts
    setIsH2Visible(true);

    return () => clearInterval(interval);
  }, []);

  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);

  // Preloading background images
  useEffect(() => {
    const images = ["bg-1.jpg", "bg-2.jpg", "bg.jpg"];
    let loadedImages = 0;

    // Preloading all images at once
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImages++;
        // Set loaded state once all images are loaded
        if (loadedImages === images.length) {
          setIsBackgroundLoaded(true);
        }
      };
    });
  }, []);

  return (
    <>
      {/* Apply the background once it's loaded */}
      <div className={`background ${isBackgroundLoaded ? "loaded" : ""}`}></div>
      <div className="banner-w3">
        <div className="banner-info-w3l">
          <h1>
            {animatedText} {/* Jifris.com animation */}
            <span> Under Construction</span>
          </h1>

          {/* Conditionally render h2 based on isH2Visible */}
          {isH2Visible && (
            <h2>
              Discover the world’s finest premium home decor,
              <br />
              delivered directly to your doorstep.
            </h2>
          )}
        </div>
      </div>
    </>
  );
};

export default App;
